.portfolio-category-list {
    --bs-gutter-x: 0.75rem;
    --bs-gutter-y: 0.75rem;
}

.portfolio-category-thumbnail {
    height: 17rem;

    @include media-breakpoint-up(md) {
        height: 15rem;
    }

    @include media-breakpoint-up(lg) {
        height: 20rem;
    }

    @include media-breakpoint-up(xl) {
        height: 20rem;
    }
    
    &-inner {
        position: relative;

        .portfolio-category-thumbnail-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba($color: $dark, $alpha: 0.6);
            transition: all 300ms;
        }
    }
}