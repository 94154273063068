/* Font weights **/
$font-weights-custom: 100, 200, 300, 400, 500, 600, 700, 800, 900;

// https://sass-lang.com/documentation/at-rules/control/each
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $font-weight in $font-weights-custom {
            .font-weight#{$infix}-#{$font-weight} {
                font-weight: $font-weight;
            }
        }
    }
}

/* Font sizes **/
$font-sizes-custom: (
    n25: $font-size-base * 0.25, 
    n50: $font-size-base * 0.50, 
    n75: $font-size-base * 0.75,
    n85: $font-size-base * 0.85, 
    1: $font-size-base,
    2: $font-size-base * 1.25,
    3: $font-size-base * 1.50,
    4: $font-size-base * 1.75,
    5: $font-size-base * 2.00,
    6: $font-size-base * 2.25,
    7: $font-size-base * 2.50,
    8: $font-size-base * 2.75,
    9: $font-size-base * 3.00,
    10: $font-size-base * 3.25,
    11: $font-size-base * 3.50,
    12: $font-size-base * 4.00,
    13: $font-size-base * 4.50,
    14: $font-size-base * 5.00,
);

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $size,$sizeInRem in $font-sizes-custom {
            .font-size#{$infix}-#{$size} {
                font-size: $sizeInRem;
            }
        }
    }
}