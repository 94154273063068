.mobile-menu {
    position: fixed;
    visibility: hidden;
    top: 0rem;
    bottom: 0;
    right: -100vw;
    width: 100vw;
    height: 100vh;
    z-index: 1001;
    transition: all 400ms;
    padding-top: 3rem;
}

.mobile-menu-state-open {
    visibility: visible;
    transform: translateX(-100vw);
}

.desktop-menu-item {
    a {
        padding-bottom: 1px;

        &:hover {
            color: $white !important;
        }
    }
}