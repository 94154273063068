.field-editor.field-editor-list .form-floating label {
    top: -32px;
    left: -16px;
}

.field-editor.field-editor-list .form-floating .checkbox-list {
    position: relative;
    top: 11px;
}

.field-editor.field-editor-list .form-floating .form-check label {
    top: auto;
    left: auto;
    font-size: 0.7rem;
    padding-right: 0.8rem;
}