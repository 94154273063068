@import "../../xFrame4/scss/variables";

// Font
$font-family-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-base: 'Microsoft PhagsPa', $font-family-sans-serif;
$font-size-base: 0.875rem;

// Colors: main
$primary: #4c4d4e;
$secondary: #95a5a6;
$info: #dd2024;

// Colors: other
$body-color: #4c4d4e;

// Colors: misc
$min-contrast-ratio: 2.5;


// Buttons
$btn-border-radius: 0;