/*! purgecss start ignore */
// Bootstrap functions
@import "../../node_modules/bootstrap/scss/functions";

// Custom variables
@import "./variables";

// Bootstrap variables
@import "../../node_modules/bootstrap/scss/variables";

// Custom maps
@import "./maps";

// Bootsrap maps
@import "../../node_modules/bootstrap/scss/maps";

// Additional Bootsrap imports
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/utilities";

// Custom utilities
@import "./utilities";

// Additional Bootsrap imports
@import "../../node_modules/bootstrap/scss/utilities/api";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/helpers";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/accordion";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/alert";
/*! purgecss end ignore */

/*! purgecss start ignore */
// Custom style
@import "./custom.scss";
/*! purgecss end ignore */