@import "../menu/menu";

/*************** Logo ****************/
img.logo {
    width: 2.5rem;
    height: 2.5rem;

    @include media-breakpoint-up(lg) {
        width: 3rem;
        height: 3rem;
    }
}

/*************** LanguageSelector ****************/
.language-list {
    position: absolute;
    right: 0;
}

.language-list-item {
    &:hover {
        color: $dark;
    }
}