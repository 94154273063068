// Links
a
{
    text-decoration: none !important;
}

a:hover
{
    color: $primary !important;
}

.classic-link, .page-component-content a
{
    color: $info !important;

    &:hover {
        color: $info !important;
        text-decoration: underline !important;
    }
}

.content-link
{
    &:hover {
        color: $info !important;
        text-decoration: underline !important;
    }
}