.contact-form-field {
    input, textarea {
        padding: 0.7rem 1rem;

        &::placeholder {
            color: $gray-500;
            font-size: $font-size-base * 1.1;
            font-weight: normal;
        }
    }

    label {
        color: $dark;
        font-weight: bold;
    }
}